import { Navigate } from 'react-router-dom';
import HomePage from './components/pages/HomePage/HomePage';
import ContactUsPage from './components/pages/ContactUsPage/ContactUsPage';
import Menu from './components/Menu/Menu';
import SettingsPage from './components/pages/SettingsPage/SettingsPage';
import RailExtensionPage from './components/pages/RailExtensionPage/RailExtensionPage';

const routesConfig = [
    {
        path: '/',
        element: <Menu />,
        children: [
            {
                path: '/',
                element: <Navigate to="/home" replace />,
            },
            {
                path: '/home',
                element: <HomePage />,
            },
            {
                path: '/rail-extension',
                element: <RailExtensionPage />,
            },
            {
                path: '/contact-us',
                element: <ContactUsPage />,
            },
            {
                path: '/settings',
                element: <SettingsPage />,
            },
        ],
    },
];

export default routesConfig;
