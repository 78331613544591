export const celsiusToFahrenheit = (celsius: number) => {
    return (celsius * 9) / 5 + 32;
};

export const fahrenheitToCelsius = (fahrenheit: number) => {
    return ((fahrenheit - 32) * 5) / 9;
};

export const metersToFeet = (meters: number) => {
    return meters * 3.28084;
};

export const feetToMeters = (feet: number) => {
    return feet / 3.28084;
};
