import { MouseEvent, useState } from 'react';
import styled from '@emotion/styled';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { capitalize } from '../../../utils/stringUtils';

const Page = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
`;

const Title = styled.div`
    font-size: 30px;
    color: rgb(227, 0, 15);
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 15px;
`;

const Subtitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
`;

const StyledImage = styled.img`
    width: 80%;
    max-width: 400px;
    margin-top: 20px;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const SettingsRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    gap: 20px;
`;

const Label = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const StyledToggleButton = styled(ToggleButton)`
    width: 100px;
`;

const SettingsPage = () => {
    const unitsFromLocalStorage = localStorage.getItem('units');
    const [units, setUnits] = useState<string>(unitsFromLocalStorage || 'metric');

    const handleChange = (event: MouseEvent<HTMLElement>, value: any) => {
        if (!value) {
            return;
        }
        setUnits(value);
        localStorage.setItem('units', value);
    };

    return (
        <Page>
            <Section>
                <StyledImage src="/img/gol-logo.svg" alt="Logo" />
                <Title>Settings</Title>
                <Subtitle></Subtitle>
            </Section>
            <Section>
                <SettingsRow>
                    <Label>
                        <div>Measurements are shown in</div>
                        <div>
                            <b>{capitalize(units)} units</b>
                        </div>
                    </Label>
                    <ToggleButtonGroup
                        color="primary"
                        value={units}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <StyledToggleButton value="metric">Metric</StyledToggleButton>
                        <StyledToggleButton value="imperial">Imperial</StyledToggleButton>
                    </ToggleButtonGroup>
                </SettingsRow>
            </Section>
        </Page>
    );
};

export default SettingsPage;
