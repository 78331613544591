import styled from '@emotion/styled';
import { Button, ThemeProvider as MUIThemeProvider, Snackbar } from '@mui/material';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { theme } from './theme';
import { useEffect, useState } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import routesConfig from './routes';
import { useServiceWorker } from './useServiceWorker';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

const router = createBrowserRouter(routesConfig);

const App = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const { waitingWorker, showReload, reloadPage } = useServiceWorker();

    useEffect(() => {
        if (showReload && waitingWorker) {
            setSnackbarOpen(true);
        } else setSnackbarOpen(false);
    }, [waitingWorker, showReload, reloadPage]);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const action = (
        <>
            <Button color="secondary" size="small" onClick={reloadPage}>
                REFRESH
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    return (
        <SCThemeProvider theme={theme}>
            <MUIThemeProvider theme={theme}>
                <Container className="App">
                    <RouterProvider router={router} />
                    <Snackbar
                        open={snackbarOpen}
                        message="A new version is available"
                        onClose={handleClose}
                        action={action}
                    />
                </Container>
            </MUIThemeProvider>
        </SCThemeProvider>
    );
};

export default App;
