import styled from '@emotion/styled';
import { Button } from '@mui/material';

const Page = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
`;

const Title = styled.div`
    font-size: 30px;
    color: rgb(227, 0, 15);
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 15px;
`;

const Subtitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
`;

const StyledImage = styled.img`
    width: 80%;
    max-width: 400px;
    margin-top: 20px;
`;

const StyledButton = styled(Button)`
    margin-top: 20px;
    width: 200px;
    background-color: #d6d8d7;
    border: none;
    height: 40px;
    font-weight: bold;
    cursor: pointer;
    background-color: rgb(227, 0, 15);
    color: white;

    &:hover {
        background-color: #aa0419;
    }
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const HomePage = () => {
    const handleButtonClick = () => {
        window.open('https://www.goldschmidt.com/en/', '_blank');
    };

    return (
        <Page>
            <Section>
                <StyledImage src="/img/gol-logo.svg" alt="Logo" />
                <Title>Welcome</Title>
                <Subtitle>You can use the menu button in the top left corner to navigate the app</Subtitle>
            </Section>
            <Section>
                <Subtitle>IMPORTANT: Only ever use your phone from a position of safety</Subtitle>
                <StyledButton onClick={handleButtonClick}>VISIT WEBSITE</StyledButton>
            </Section>
        </Page>
    );
};

export default HomePage;
