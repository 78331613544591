import { createTheme } from '@mui/material';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#E3140E',
        },
        secondary: {
            main: '#757575',
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: 'white',
                    color: 'black',
                },
            },
        },
        // MuiListItemButton: {
        //     styleOverrides: {
        //         root: {
        //             color: 'black',
        //             '&.Mui-selected': {
        //                 backgroundColor: 'white',
        //                 color: '#E3140E',
        //             },
        //         },
        //     },
        // },
    },
});
